var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from "react";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
import { accountsFetcher, contactsFetcher, usersFetcher, } from "../calls_table/calls_table_fetchers";
export function useFilterConfig(FIELDS) {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            [
                "owner_id",
                (userQuery, ids, cursor, queryClient) => __awaiter(this, void 0, void 0, function* () {
                    const { results, next_cursor } = yield usersFetcher(userQuery, ids, cursor, queryClient);
                    results.unshift({
                        value: "-99",
                        label: "Self",
                    });
                    return {
                        results: results.filter((result) => result.label.toLowerCase().includes(userQuery.toLowerCase())),
                        next_cursor,
                    };
                }),
            ],
        ]);
    }, []);
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 5,
            referenceObjectFetcherMap,
        };
    }, [FIELDS]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    return { filterConfig, wiserBackendQueryConverter };
}
export function useInitialFilter(searchParams, wiserBackendQueryConverter) {
    return useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams, wiserBackendQueryConverter]);
}
export function useFilterChange(navigate, searchParams) {
    return useCallback((updatedFilter) => {
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        navigate(`?${updateSearchParams([
            {
                key: "filter",
                value: filterParam,
            },
            {
                key: "page",
                value: "1",
            },
        ], searchParams)}`);
    }, [navigate, searchParams]);
}
